export default function BoltSimple({ color = "white" }) {
  return (
    <svg
      width="14"
      height="26"
      viewBox="0 0 14 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Bolt Simple</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.492555 13.9909L10.7943 0L7.64635 10.9775C7.64548 10.9904 7.64504 11.0024 7.64504 11.0134C7.64504 11.2128 7.79071 11.3745 7.9704 11.3745L7.99542 11.3748H7.53243L7.53234 11.3751H13.29C13.618 11.3751 13.8066 11.7482 13.612 12.0123L3.30663 26.0008L6.40305 15.2136C6.42344 15.1256 6.43486 15.0444 6.43486 14.9886C6.43486 14.7891 6.28919 14.6274 6.10949 14.6274C6.0974 14.6274 6.08399 14.6272 6.0695 14.6268L0.814763 14.6281C0.48672 14.6282 0.298052 14.2551 0.492555 13.9909Z"
        fill={color}
      />
    </svg>
  );
}
