export default function Chevron() {
  return (
    <svg
      width="13"
      height="22"
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Chevron</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72793 1.41412C2.94689 0.633073 1.68056 0.633073 0.899507 1.41412C0.118459 2.19517 0.118459 3.4615 0.899507 4.24255L7.97057 11.3136L0.899507 18.3847C0.118459 19.1657 0.118459 20.4321 0.899507 21.2131C1.68056 21.9942 2.94689 21.9942 3.72793 21.2131L12.2132 12.7278C12.9943 11.9468 12.9943 10.6805 12.2132 9.8994L3.72793 1.41412Z"
        fill="white"
      />
    </svg>
  );
}
