const Check = ({ fill = "white" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Check</title>
    <rect
      x="3.51465"
      y="11.9995"
      width="12"
      height="4"
      transform="rotate(45 3.51465 11.9995)"
      fill={fill}
    />
    <rect
      x="20.4852"
      y="6.34277"
      width="4"
      height="20"
      transform="rotate(45 20.4852 6.34277)"
      fill={fill}
    />
  </svg>
);

export default Check;
