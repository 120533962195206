import s from "styled-components";

const Logo = ({ color = "black", size = "large" }) => (
  <Wrapper size={size}>
    {size === "large" ? (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Publit</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.89543 0 2V28C0 29.1046 0.89543 30 2 30H28C29.1046 30 30 29.1046 30 28V2C30 0.895431 29.1046 0 28 0H2ZM6 5C5.44772 5 5 5.44772 5 6V24C5 24.5523 5.44772 25 6 25H24C24.5523 25 25 24.5523 25 24V6C25 5.44772 24.5523 5 24 5H6Z"
          fill={color}
        />
      </svg>
    ) : (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Publit</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM3.66667 2.66667C3.11438 2.66667 2.66667 3.11438 2.66667 3.66667V16.3333C2.66667 16.8856 3.11438 17.3333 3.66667 17.3333H16.3333C16.8856 17.3333 17.3333 16.8856 17.3333 16.3333V3.66667C17.3333 3.11438 16.8856 2.66667 16.3333 2.66667H3.66667Z"
          fill={color}
        />
      </svg>
    )}
  </Wrapper>
);

const Wrapper = s.span`
display: inline-block;
height: ${(props) => (props.size === "large" ? "30px" : "20px")};
width: ${(props) => (props.size === "large" ? "30px" : "20px")};
margin: 0;
`;

export default Logo;
