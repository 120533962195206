const MoveRemove = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Remove</title>
    <path
      d="M0.535534 7.60659L7.6066 0.535522M7.6066 7.60659L0.535534 0.535522"
      stroke="var(--color-error)"
    />
  </svg>
);

export default MoveRemove;
