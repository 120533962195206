import s from "styled-components";

const PricingCheck = ({ color = "var(--color-primary)" }) => (
  <Icon
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Check</title>
    <path
      d="M14.8885 2.57026C15.6941 1.62564 14.3984 0.350381 13.4667 1.17092L5.59957 8.09954L2.79585 5.29533C2.63486 5.13431 2.37211 5.13997 2.2182 5.30778L0.333691 7.36244C0.194678 7.51401 0.192208 7.74456 0.331873 7.89552C0.793976 8.39502 1.84997 9.50773 2.93218 10.4487L2.90683 10.471L4.99225 12.5235C5.40587 12.9306 6.07801 12.9013 6.45459 12.4597L14.8885 2.57026Z"
      fill={color}
    />
  </Icon>
);

export default PricingCheck;

const Icon = s.svg`
flex-shrink: 0;
padding-right: 10px;
`;
