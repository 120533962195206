const Plug = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Plug</title>
    <path
      id="big-hole"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 20V24H10H6V20C6 17.7909 7.79083 16 10 16C12.2092 16 14 17.7909 14 20Z"
      fill="white"
    />
    <rect id="right-hole" x="18" width="2" height="8" fill="white" />
    <rect id="left-hole" width="2" height="8" fill="white" />

    <animate
      xlinkHref="#big-hole"
      attributeType="CSS"
      attributeName="fill"
      values="white; #FAFF00; white"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="big-hole-a"
      begin="0s; big-hole-a.end + 1s"
    />
    <animate
      xlinkHref="#right-hole"
      attributeType="CSS"
      attributeName="fill"
      values="white; #FAFF00; white"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="right-hole-a"
      begin="0s; right-hole-a.end + 1s"
    />
    <animate
      xlinkHref="#left-hole"
      attributeType="CSS"
      attributeName="fill"
      values="white; #FAFF00; white"
      keyTimes="0; 0.1; 1"
      dur="1s"
      id="left-hole-a"
      begin="0s; left-hole-a.end + 1s"
    />
  </svg>
);

export default Plug;
