import { css } from "styled-components";

export const party = (
  visible: "always" | "hover" | "focus" | "active",
  amount: "normal" | "small" = "normal",
) => {
  const amounts = { normal: [5, 20], small: [2, 10] };
  const v = amounts[amount];

  const rules = {
    boxShadow: `-${v[0]}px -${v[0]}px ${v[1]}px rgba(var(--color-pink-raw), 0.4), ${v[0]}px ${v[0]}px ${v[1]}px rgba(var(--color-light-blue-raw), 0.4)`,
  };

  if (visible === "always") {
    return css(rules);
  } else {
    return css({
      [`&:${visible}`]: rules,
    });
  }
};
