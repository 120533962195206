import s from "styled-components";

const PricingX = () => (
  <Icon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>X</title>
    <path
      d="M0.573961 11.8868C0.217351 11.5302 0.182533 10.965 0.500223 10.5734C1.38431 9.48335 3.22807 7.27716 5.25304 5.25219C7.27802 3.22721 9.48421 1.38346 10.5742 0.499368C10.9659 0.181678 11.5311 0.216496 11.8877 0.573106L12.0534 0.738877C12.4101 1.09549 12.4449 1.66066 12.1272 2.05234C11.2431 3.14234 9.39934 5.34853 7.37436 7.37351C5.34939 9.39848 3.14319 11.2422 2.05319 12.1263C1.66151 12.444 1.09634 12.4092 0.739732 12.0526L0.573961 11.8868Z"
      fill="#FF0F00"
    />
    <path
      d="M0.500223 2.05234C0.182533 1.66066 0.217351 1.09549 0.573961 0.738877L0.739732 0.573106C1.09634 0.216496 1.66151 0.181678 2.05319 0.499368C3.14319 1.38346 5.34939 3.22721 7.37436 5.25219C9.39934 7.27716 11.2431 9.48335 12.1272 10.5734C12.4449 10.965 12.4101 11.5302 12.0534 11.8868L11.8877 12.0526C11.5311 12.4092 10.9659 12.444 10.5742 12.1263C9.48421 11.2422 7.27802 9.39848 5.25304 7.37351C3.22807 5.34853 1.38431 3.14234 0.500223 2.05234Z"
      fill="#FF0F00"
    />
  </Icon>
);

export default PricingX;

const Icon = s.svg`
flex-shrink: 0;
padding-right: 13px;
`;
