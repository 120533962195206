const Pro = ({ plain }) => (
  <svg
    width="120"
    height="44"
    viewBox="0 0 120 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Pro</title>
    <path
      d="M31.5769 13.9786C31.5769 16.4164 31.1261 18.4808 30.2244 20.1716C29.3228 21.8231 28.1075 23.16 26.5787 24.1823C25.0498 25.2046 23.2857 25.9517 21.2865 26.4236C19.2872 26.8954 17.2095 27.1314 15.0534 27.1314H10.0552V42.8794H0V1.12065H15.2886C17.5623 1.12065 19.6792 1.35657 21.6393 1.82842C23.6385 2.26095 25.3634 2.98838 26.8139 4.01072C28.3035 4.99375 29.46 6.311 30.2832 7.96247C31.1457 9.57462 31.5769 11.58 31.5769 13.9786ZM21.5217 14.0375C21.5217 13.0545 21.3257 12.2484 20.9336 11.6193C20.5416 10.9902 20.0124 10.4987 19.346 10.1448C18.6796 9.79089 17.9151 9.55496 17.0527 9.437C16.2295 9.31903 15.367 9.26005 14.4654 9.26005H10.0552V19.1099H14.289C15.2298 19.1099 16.1314 19.0313 16.9939 18.874C17.8563 18.7167 18.6207 18.4415 19.2872 18.0483C19.9928 17.6551 20.5416 17.1439 20.9336 16.5147C21.3257 15.8463 21.5217 15.0206 21.5217 14.0375Z"
      fill={plain ? "white" : "url(#gradient)"}
    />
    <path
      d="M59.8989 42.8794L50.9022 26.3056H47.4916V42.8794H37.6128V1.12065H53.4895C55.4888 1.12065 57.4292 1.33691 59.3109 1.76944C61.2318 2.16265 62.9371 2.85076 64.4267 3.83378C65.9556 4.8168 67.1708 6.11439 68.0725 7.72654C68.9741 9.3387 69.4249 11.3441 69.4249 13.7426C69.4249 16.5737 68.6605 18.9526 67.1316 20.8794C65.6028 22.8061 63.4859 24.1823 60.781 25.008L71.6594 42.8794H59.8989ZM59.4873 13.9196C59.4873 12.9366 59.2717 12.1501 58.8405 11.5603C58.4485 10.9312 57.9193 10.4593 57.2528 10.1448C56.6256 9.79089 55.9004 9.55496 55.0771 9.437C54.2931 9.31903 53.5287 9.26005 52.7838 9.26005H47.4328V18.992H52.1958C53.0191 18.992 53.8619 18.933 54.7243 18.815C55.5868 18.6577 56.3708 18.4021 57.0764 18.0483C57.782 17.6944 58.3505 17.1832 58.7817 16.5147C59.2521 15.8463 59.4873 14.9812 59.4873 13.9196Z"
      fill={plain ? "white" : "url(#gradient)"}
    />
    <path
      d="M120 21.8231C120 25.126 119.432 28.1537 118.295 30.9062C117.158 33.6193 115.57 35.9589 113.532 37.9249C111.532 39.8517 109.141 41.3458 106.358 42.4075C103.575 43.4692 100.556 44 97.3023 44C94.0486 44 91.03 43.4692 88.2467 42.4075C85.5026 41.3458 83.1113 39.8517 81.0728 37.9249C79.0735 35.9589 77.5055 33.6193 76.3686 30.9062C75.2318 28.1537 74.6634 25.126 74.6634 21.8231C74.6634 18.4808 75.2318 15.4727 76.3686 12.7989C77.5055 10.0858 79.0735 7.78552 81.0728 5.89812C83.1113 4.01073 85.5026 2.55586 88.2467 1.53351C91.03 0.511171 94.0486 0 97.3023 0C100.556 0 103.575 0.511171 106.358 1.53351C109.141 2.55586 111.532 4.01073 113.532 5.89812C115.57 7.78552 117.158 10.0858 118.295 12.7989C119.432 15.4727 120 18.4808 120 21.8231ZM109.239 21.8231C109.239 20.0143 108.945 18.3235 108.357 16.7507C107.769 15.1778 106.946 13.8409 105.887 12.7399C104.868 11.5996 103.614 10.7149 102.124 10.0858C100.674 9.41734 99.0664 9.08311 97.3023 9.08311C95.5382 9.08311 93.931 9.41734 92.4805 10.0858C91.03 10.7149 89.7756 11.5996 88.7171 12.7399C87.6587 13.8409 86.8355 15.1778 86.2474 16.7507C85.6986 18.3235 85.4242 20.0143 85.4242 21.8231C85.4242 23.7105 85.7182 25.4602 86.3062 27.0724C86.8943 28.6452 87.6979 30.0018 88.7171 31.1421C89.7756 32.2824 91.03 33.1868 92.4805 33.8552C93.931 34.4844 95.5382 34.7989 97.3023 34.7989C99.0664 34.7989 100.674 34.4844 102.124 33.8552C103.575 33.1868 104.829 32.2824 105.887 31.1421C106.946 30.0018 107.769 28.6452 108.357 27.0724C108.945 25.4602 109.239 23.7105 109.239 21.8231Z"
      fill={plain ? "white" : "url(#gradient)"}
    />
    <defs>
      <linearGradient
        id="gradient"
        x1="0"
        y1="22"
        x2="120"
        y2="22"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--color-primary)" />
        <stop offset="1" stopColor="var(--color-secondary)" />
      </linearGradient>
    </defs>
  </svg>
);

export default Pro;
