const Forms = () => (
  <svg
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Forms</title>
    <rect width="30" height="40" rx="2" fill="white" />
    <line x1="15" y1="33.5" x2="25" y2="33.5" stroke="black" />
    <line x1="5" y1="33.5" x2="13" y2="33.5" stroke="black" />
    <line
      id="line-d"
      opacity="0"
      x1="5"
      y1="29.5"
      x2="25"
      y2="29.5"
      stroke="black"
    />
    <line x1="19" y1="25.5" x2="25" y2="25.5" stroke="black" />
    <line x1="9" y1="25.5" x2="17" y2="25.5" stroke="black" />
    <line x1="5" y1="25.5" x2="7" y2="25.5" stroke="black" />
    <line
      id="line-c"
      opacity="0"
      x1="5"
      y1="21.5"
      x2="25"
      y2="21.5"
      stroke="black"
    />
    <line x1="13" y1="17.5" x2="25" y2="17.5" stroke="black" />
    <line
      id="line-b"
      opacity="0"
      x1="5"
      y1="17.5"
      x2="11"
      y2="17.5"
      stroke="black"
    />
    <line x1="19" y1="13.5" x2="25" y2="13.5" stroke="black" />
    <line x1="15" y1="13.5" x2="17" y2="13.5" stroke="black" />
    <line x1="5" y1="13.5" x2="13" y2="13.5" stroke="black" />
    <line
      id="line-a"
      opacity="0"
      x1="15"
      y1="9.5"
      x2="25"
      y2="9.5"
      stroke="black"
    />
    <line x1="5" y1="9.5" x2="13" y2="9.5" stroke="black" />
    <line x1="5" y1="5.5" x2="25" y2="5.5" stroke="black" />

    <animate
      xlinkHref="#line-a"
      attributeType="CSS"
      attributeName="opacity"
      values="0; 1; 1; 0"
      keyTimes="0; 0.1; 0.9; 1"
      dur="5s"
      id="anim-linea"
      begin="0s; anim-lined.end + 0.5s"
    />

    <animate
      xlinkHref="#line-b"
      attributeType="CSS"
      attributeName="opacity"
      values="0; 1; 1; 0"
      keyTimes="0; 0.1; 0.9; 1"
      dur="4.5s"
      id="anim-lineb"
      begin="anim-linea.begin + 0.5s"
    />

    <animate
      xlinkHref="#line-c"
      attributeType="CSS"
      attributeName="opacity"
      values="0; 1; 1; 0"
      keyTimes="0; 0.1; 0.9; 1"
      dur="4s"
      id="anim-linec"
      begin="anim-lineb.begin + 0.5s"
    />

    <animate
      xlinkHref="#line-d"
      attributeType="CSS"
      attributeName="opacity"
      values="0; 1; 1; 0"
      keyTimes="0; 0.1; 0.9; 1"
      dur="3.5s"
      id="anim-lined"
      begin="anim-linec.begin + 0.5s"
    />
  </svg>
);

export default Forms;
