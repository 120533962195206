export default function CheckSmall({ color = "white" }) {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Check Small</title>
      <path
        d="M4.75969 12.1476L16.1475 0.759766L18.0455 2.65774L7.36476 13.3385C6.97424 13.729 6.34108 13.729 5.95055 13.3385L4.75969 12.1476Z"
        fill={color}
      />
      <path
        d="M0 7.38872L1.89797 5.49075L8.55567 12.1484L7.36476 13.3385C6.97424 13.729 6.34108 13.729 5.95055 13.3385L0 7.38872Z"
        fill={color}
      />
    </svg>
  );
}
