const Bolt = () => (
  <svg
    width="25"
    height="36"
    viewBox="0 0 25 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Bolt</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 20.2536L19.3231 0L14.6824 15.75H24.0012L8.67268 36L13.3175 20.2514L4 20.2536Z"
      fill="#05E200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 20.2536L15.3231 0L10.6824 15.75H20.0012L4.67268 36L9.31753 20.2514L0 20.2536Z"
      fill="url(#paint0_linear_60_553)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_60_553"
        x1="10.0006"
        y1="0"
        x2="10.0006"
        y2="36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C2FF" />
        <stop offset="1" stopColor="#016AC9" />
      </linearGradient>
    </defs>
  </svg>
);

export default Bolt;
