export default function RocketSimple({ color = "white" }) {
  return (
    <svg
      width="16"
      height="30"
      viewBox="0 0 16 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Rocket</title>
      <rect
        x="8.66644"
        y="21"
        width="1.33333"
        height="4"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="4.66644"
        y="27.667"
        width="1.33333"
        height="1.33333"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="10.6664"
        y="26.333"
        width="1.33333"
        height="2.66667"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="6.66644"
        y="27"
        width="1.33333"
        height="2.66667"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="10.6664"
        y="24.333"
        width="1.33333"
        height="1.33333"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="8.66644"
        y="25.667"
        width="1.33333"
        height="4"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="10.6664"
        y="21"
        width="1.33333"
        height="2.66667"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="4.66644"
        y="24.333"
        width="1.33333"
        height="2.66667"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="6.66644"
        y="21"
        width="1.33333"
        height="5.33333"
        rx="0.666667"
        fill={color}
      />
      <rect
        x="4.66653"
        y="21"
        width="1.33333"
        height="2.66667"
        rx="0.666667"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99996 0.333008C8.54448 0.333008 11.5159 4.53916 12.3761 10.2007L14.1059 11.9765C14.8918 12.7831 15.3333 13.8771 15.3333 15.0178V19.1502C15.3333 19.3284 15.1926 19.4728 15.019 19.4728C14.9674 19.4728 14.9166 19.4598 14.8711 19.4348L12.2552 18.0029C12.1554 18.6533 12.0365 19.2777 11.9011 19.8711C10.6027 20.179 9.30418 20.333 8.00565 20.333C6.706 20.333 5.40365 20.1788 4.0986 19.8703C3.96331 19.2772 3.84451 18.653 3.74478 18.0029L1.12881 19.4348C0.975656 19.5187 0.785283 19.4592 0.703601 19.302C0.679324 19.2553 0.666626 19.2032 0.666626 19.1502V15.0178C0.666626 13.8771 1.10812 12.7831 1.89399 11.9765L3.62382 10.2008C4.484 4.53921 7.45544 0.333008 7.99996 0.333008ZM7.93508 1.2564L7.92514 1.26512L7.8839 1.30679C7.66399 1.53287 7.4447 1.82019 7.22604 2.16877C6.99094 2.54355 6.80694 2.90313 6.67403 3.24748C6.65465 3.29769 6.67465 3.35414 6.71927 3.38035L6.73 3.38585L6.79349 3.4132C7.19559 3.58196 7.59769 3.66634 7.99979 3.66634C8.42437 3.66634 8.84905 3.57226 9.27382 3.38411C9.32048 3.36344 9.34541 3.3118 9.33458 3.26205L9.33112 3.24967L9.31202 3.19541C9.2003 2.88602 9.02987 2.5438 8.80072 2.16877C8.53862 1.79948 8.29588 1.49883 8.0725 1.26682C8.03524 1.22812 7.97638 1.2245 7.93508 1.2564Z"
        fill={color}
      />
    </svg>
  );
}
