const Pin = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Pin</title>
    <path
      d="M16 8C16 12.4183 8 20 8 20C8 20 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      fill="#D8DCEB"
    />
    <circle cx="8" cy="8" r="4" fill="white" />
  </svg>
);

export default Pin;
