const WWW = ({ lettersOnly }) => (
  <svg
    width="50"
    height="30"
    viewBox="0 0 50 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>WWW</title>
    <rect
      width="50"
      height="30"
      rx="2"
      fill={lettersOnly ? "transparent" : "black"}
    />
    <path
      id="three"
      d="M42.8385 12.154L40.1505 19H38.6245L36.9445 14.59L35.3065 19H33.7945L31.0925 12.154H32.8845L34.6345 16.914L36.3845 12.154H37.6445L39.3945 16.942L41.1305 12.154H42.8385Z"
      fill="white"
      opacity="0"
    />
    <path
      id="two"
      d="M30.8482 12.154L28.1602 19H26.6342L24.9542 14.59L23.3162 19H21.8042L19.1022 12.154H20.8942L22.6442 16.914L24.3942 12.154H25.6542L27.4042 16.942L29.1402 12.154H30.8482Z"
      fill="white"
      opacity="0"
    />
    <path
      id="one"
      d="M18.858 12.154L16.17 19H14.644L12.964 14.59L11.326 19H9.814L7.112 12.154H8.904L10.654 16.914L12.404 12.154H13.664L15.414 16.942L17.15 12.154H18.858Z"
      fill="white"
    />

    <animate
      xlinkHref="#two"
      attributeType="CSS"
      attributeName="opacity"
      values="0; 1; 1; 0"
      keyTimes="0; 0.25; 0.5; 1"
      dur="2s"
      id="anim-two"
      begin="0s; anim-three.end + 1s"
    />

    <animate
      xlinkHref="#three"
      attributeType="CSS"
      attributeName="opacity"
      values="0; 1; 1; 0"
      keyTimes="0; 0.25; 0.5; 1"
      dur="1.5s"
      id="anim-three"
      begin="anim-two.begin + 0.5s"
    />
  </svg>
);

export default WWW;
