export default function Lock() {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Lock</title>
      <rect y="3" width="6" height="4.8" rx="0.5" fill="#444444" />
      <path
        d="M4.79995 2.3L4.49995 2.3L4.49995 2.6H4.79995V2.3ZM4.79998 2.3H5.09998V2H4.79998V2.3ZM4.79998 4.1V4.4H5.09998V4.1H4.79998ZM3.00403 4.1V3.79999L3.00335 3.8L3.00403 4.1ZM2.99587 4.1L2.99655 3.8H2.99587V4.1ZM1.19998 4.1H0.899982V4.4H1.19998V4.1ZM1.19998 2.31059H1.49999L1.49998 2.30886L1.19998 2.31059ZM4.79995 2.6H4.79998V2H4.79995V2.6ZM4.49998 2.3V4.1H5.09998V2.3H4.49998ZM4.79998 3.8H3.00403V4.4H4.79998V3.8ZM3.00335 3.8C3.00223 3.8 3.0011 3.8 2.99995 3.8V4.4C3.00152 4.4 3.00311 4.4 3.00472 4.39999L3.00335 3.8ZM2.99995 3.8C2.9988 3.8 2.99767 3.8 2.99655 3.8L2.99519 4.39999C2.99679 4.4 2.99838 4.4 2.99995 4.4V3.8ZM2.99587 3.8H1.19998V4.4H2.99587V3.8ZM1.49998 4.1V2.31059H0.899982V4.1H1.49998ZM1.49998 2.30886C1.49996 2.30591 1.49995 2.30296 1.49995 2.3H0.899951C0.899951 2.30411 0.899963 2.30822 0.899987 2.31232L1.49998 2.30886ZM1.49995 2.3C1.49995 1.47157 2.17152 0.8 2.99995 0.8V0.2C1.84015 0.2 0.899951 1.1402 0.899951 2.3H1.49995ZM2.99995 0.8C3.82838 0.8 4.49995 1.47157 4.49995 2.3L5.09995 2.29999C5.09995 1.1402 4.15975 0.2 2.99995 0.2V0.8Z"
        fill="#444444"
      />
    </svg>
  );
}
