import { css } from "styled-components";
import type { Styles } from "styled-components/dist/types";

type CSSObject = Styles<Record<string, unknown>>;

export type AttributesMap<T extends string | number | symbol> = {
  [key in T]: CSSObject;
};

export type SpecialAttributes = "capped" | "misc";

const SpecialAttributesMap: AttributesMap<SpecialAttributes> = {
  capped: { textTransform: "uppercase" },
  misc: { textTransform: "none" },
};

export type FontFaces = "crimson" | "manrope" | "nunito";

const FontFacesMap: AttributesMap<FontFaces> = {
  crimson: { fontFamily: "'Crimson Text', serif" },
  manrope: { fontFamily: "Manrope, sans-serif" },
  nunito: { fontFamily: "inherit" },
};

export type FontSizes = "xs" | "s" | "medium" | "large" | "xl" | "xxl" | "xxxl";

const FontSizesMap: AttributesMap<FontSizes> = {
  xs: { fontSize: 12 },
  s: { fontSize: 14 },
  medium: { fontSize: 16 },
  large: { fontSize: 18 },
  xl: { fontSize: 20 },
  xxl: { fontSize: 24 },
  xxxl: { fontSize: 40 },
};

export type FontWeights = "light" | "normal" | "semibold" | "bold" | "black";

const FontWeightsMap: AttributesMap<FontWeights> = {
  light: { fontWeight: "lighter" },
  normal: { fontWeight: "normal" },
  semibold: { fontWeight: 600 },
  bold: { fontWeight: "bold" },
  black: { fontWeight: "bolder" },
};

export const combineAttributes = (objects: CSSObject[]): CSSObject => {
  // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
  return objects.reduce((prev, current) => ({ ...prev, ...current }), {});
};

export const build = (
  face: FontFaces,
  size: FontSizes,
  weight: FontWeights = "normal",
  specialAttributes: SpecialAttributes[] = [],
) => {
  const rules = {
    ...FontFacesMap[face],
    ...FontSizesMap[size],
    ...FontWeightsMap[weight],
    ...combineAttributes(
      specialAttributes.map((key) => SpecialAttributesMap[key]),
    ),
    lineHeight: "normal",
  };

  return css(rules);
};
