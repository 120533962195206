const Editing = () => (
  <svg
    width="50"
    height="30"
    viewBox="0 0 50 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Editing</title>
    <rect width="50" height="30" rx="2" fill="black" />
    <path
      d="M29.9131 9.08203L30.0449 11.877H29.7109C29.6465 11.3848 29.5586 11.0332 29.4473 10.8223C29.2656 10.4824 29.0225 10.2334 28.7178 10.0752C28.4189 9.91113 28.0234 9.8291 27.5312 9.8291H25.8525V18.9346C25.8525 19.667 25.9316 20.124 26.0898 20.3057C26.3125 20.5518 26.6553 20.6748 27.1182 20.6748H27.5312V21H22.4775V20.6748H22.8994C23.4033 20.6748 23.7607 20.5225 23.9717 20.2178C24.1006 20.0303 24.165 19.6025 24.165 18.9346V9.8291H22.7324C22.1758 9.8291 21.7803 9.87012 21.5459 9.95215C21.2412 10.0635 20.9805 10.2773 20.7637 10.5938C20.5469 10.9102 20.418 11.3379 20.377 11.877H20.043L20.1836 9.08203H29.9131Z"
      fill="white"
    />
    <line id="bar" x1="32.5" y1="7" x2="32.5" y2="23" stroke="white" />

    <animate
      xlinkHref="#bar"
      attributeType="CSS"
      attributeName="opacity"
      values="0; 1; 0"
      keyTimes="0; 0.5; 1"
      dur="1s"
      calcMode="spline"
      keySplines="0.4 0 .52 .99; 0.4 0 .52 .99"
      repeatCount="indefinite"
    />
  </svg>
);

export default Editing;
